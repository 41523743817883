@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/corner-radius';

.select-club {
  $breakpoint: 800px;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: light.$fixed-background-primary-default;
  color: light.$on-fixed-surface-primary-default;
  padding: spacing.$xl spacing.$m;

  @media (min-width: $breakpoint) {
    justify-content: center;
  }

  &__messages {
    position: fixed;
    max-width: 800px;
    width: 100%;
    top: spacing.$xl;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 spacing.$xs;
    z-index: 99;
    color: light.$on-background-primary-default;
  }

  &__logo {
    margin-bottom: spacing.$xl;
  }

  &__logo-desktop {
    display: none;

    @media (min-width: $breakpoint) {
      display: block;
    }
  }

  &__logo-mobile {
    display: block;

    @media (min-width: $breakpoint) {
      display: none;
    }
  }

  &__title {
    margin-bottom: spacing.$s;
  }

  &__content {
    background-color: light.$fixed-surface-primary-default;
    border-radius: corner-radius.$m;
    padding: spacing.$m;
    margin: spacing.$m;
    max-width: 640px;
    width: 100%;
    text-align: left;

    &--extra-padding {
      padding: spacing.$l;
    }
  }

  &__logged-in {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logged-in-text {
    color: light.$on-fixed-surface-primary-alternate;
  }

  &__select {
    margin-bottom: spacing.$l;
  }

  &__error {
    color: light.$on-surface-primary-default;
  }
}
